import React from "react";
import { Divider } from "../../components/Divider";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function IconsPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Icons"
        description="Icons help decorate and visually communicate actions as an alternative to text. They are best used for repeated or persistent actions when that action can be easily recognized with an image."
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=8853%3A540330"
        link2title="View MAF Storybook"
        link2url="https://webcomponents.maf.nielsenmedia.com/gds/?path=/docs/components-icon-icons--preview"
        link3title="View Web Components Storybook"
        link3url="https://nielsen-media.gitlab.io/ma/design-libraries/gds-web-components/development/storybook/?path=/docs/foundations-icons-interface-icons-icons--icons"
        tocItems={[
          "Icon Library",
          "Using Icons in Figma",
          "Using Marketing Pictograms",
        ]}
      >
        <React.Fragment>
          <div className="section" id="icon-library">
            <h2>Icon Library</h2>
            <p>
              The GDS icon library is a first pass at consolidating all of the
              icons used across Nielsen products. This library will grow over
              time to include more icons from Global Panel, Gracenote, and other
              Nielsen icons we haven’t yet included. If you need an icon that
              currently isn’t found in our library, please contact us so we can
              get it included in our backlog.{" "}
            </p>
            <p>
              We have plans in our roadmap to update this page to be a resource
              to search for and copy code for icons. Check back often to see our
              progress towards that end.
            </p>
          </div>
          <Divider />

          <div className="section" id="using-icons-in-figma">
            <h2>Using Icons in Figma</h2>
            <p>
              If you’d like to use an icon in your designs there are 2
              components to choose between. If an icon has any interaction, use
              the action-icon component. If the icon is only for display, use
              the display-icon component. After you add the component to your
              file, update the icon file inside of the component to whatever
              icon you'd like.
            </p>
            <p>
              Using these components will ensure our icons have a proper size
              that aligns to our tokens. In the display-icon component, there is
              an option with a background if that works best in your design.We
              have preset color combinations for every color (most commonly a
              foreground color of [color]-600 and background of [color]-100) in
              both light mode and dark mode, which are available directly in
              MAF.
            </p>
            <p>
              Please reach out to the GDS team if you have any questions about
              which icons are most commonly used in different scenarios.
            </p>
          </div>
          <Divider />

          <div className="section" id="using-marketing-pictograms">
            <h2>Using Marketing Pictograms</h2>
            <p>
              Nielsen's marketing team has created a set of (2000+!) icons for
              the company. These icons are created to reflect the current
              branding. They’re graphics that have specific usages to
              communicate a variety of ideas. In GDS, to avoid confusion, we
              call these icons pictograms to denote they represent a word or
              idea.{" "}
            </p>
            <p>
              Pictograms should never be used as an alternative to the interface
              icons. On occasion, they can be used as a decorative, ornamental
              element in a design. Please reach out to the marketing team if you
              have any questions about how, when, or which icons should be used
              to align to their messaging.
            </p>
          </div>
          <Divider />
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
